import { AsyncPipe } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import * as fromApp from '@app/reducers';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-greeting',
  templateUrl: './greeting.component.html',
  standalone: true,
  imports: [AsyncPipe]
})
export class GreetingComponent {
  private readonly store = inject<Store<fromApp.State>>(Store);

  @Input() text: string;

  salutation: string;
  authState$: Observable<any>;
  givenname$: Observable<any>;

  constructor() {
    const store = this.store;

    const hour = new Date().getHours();
    if (hour < 12) {
      this.salutation = 'Good Morning';
    } else if (hour >= 12 && hour < 18) {
      this.salutation = 'Good Afternoon';
    } else {
      this.salutation = 'Good Evening';
    }

    this.authState$ = store.pipe(select(fromApp.selectAuthState));
    this.givenname$ = this.authState$.pipe(map(auth => auth.givenname));
  }
}
