import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';
import { HotelChainDropdown } from '../../shared/data/hotel-chain-dropdown';

@Injectable({
  providedIn: 'root'
})
export class HotelChainDropdownService {
  private readonly http = inject(HttpClient);
  private readonly api = inject(ApiEndpointsService);

  get(): Observable<HotelChainDropdown[]> {
    return this.http.get<HotelChainDropdown[]>(
      this.api.endpoints.dynamicConnect + '/HotelChain/Dropdown'
    );
  }
}
