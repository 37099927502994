import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastActions } from '../../core/actions/toast.action';
import { FlightInfoActions } from '../actions/flightinfo.actions';
import { MemoService } from '../services/memo.service';

@Injectable()
export class MemoEffects {
  private readonly actions$ = inject(Actions);
  private readonly memoService = inject(MemoService);

  onRequestMemos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlightInfoActions.getFlightInfos),
      switchMap(req =>
        this.memoService
          .getMemos(
            req.tourOperator,
            req.externalReservationNumber,
            req.version
          )
          .pipe(
            map(returnedMemos =>
              FlightInfoActions.getMemosSuccess({
                memos: returnedMemos
              })
            ),
            catchError((error: HttpErrorResponse) => {
              return of(
                FlightInfoActions.clearFlightInfosStore(),
                ToastActions.errorToast({
                  title: 'An Error Occurred',
                  description:
                    typeof error.error === 'string'
                      ? error.error
                      : error.statusText
                })
              );
            })
          )
      )
    )
  );
}
