import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';
import { CoreConsumerMessage } from '../data/core-consumer-message/core-consumer-message';
import { Reimport } from '../data/reimport';
import { RequestEntity } from '../data/request-entity';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  private readonly http = inject(HttpClient);
  private readonly apiEndpoints = inject(ApiEndpointsService);

  reimport(reimports: Reimport[]): Observable<Reimport[]> {
    return this.http.post<Reimport[]>(
      `${this.apiEndpoints.endpoints.requests}/reimport`,
      reimports
    );
  }

  get(
    tourOperator: string,
    externalReservationNumber: string,
    version: string
  ): Observable<RequestEntity<CoreConsumerMessage> | RequestEntity<any>> {
    const url = `${this.apiEndpoints.endpoints.requests}/${tourOperator}/${externalReservationNumber}/${version}`;

    if (!tourOperator)
      return throwError(() => new Error('Missing tour operator code'));
    if (!externalReservationNumber)
      return throwError(() => new Error('Missing external reservation number'));
    if (!version)
      return throwError(() => new Error('Missing external version'));

    return this.http
      .get<RequestEntity<CoreConsumerMessage> | RequestEntity<any>>(url, {
        observe: 'response',
        responseType: 'json'
      })
      .pipe(map(resp => resp.body));
  }
}
