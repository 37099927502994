import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DsaBadgeModule } from '@dsa/design-system-angular/badge';
import { DsaCardModule } from '@dsa/design-system-angular/card';
import { DsaIconModule } from '@dsa/design-system-angular/icon';
import { DsaInfoCardModule } from '@dsa/design-system-angular/info-card';
import { DsaTooltipModule } from '@dsa/design-system-angular/tooltip';
import { StoreModule } from '@ngrx/store';
import { JobNotificationsDisplayComponent } from './components/job-notifications-display/job-notifications-display.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import * as fromNotifications from './reducers/job-notifications.reducer';

@NgModule({
  imports: [
    CommonModule,
    DsaBadgeModule,
    DsaCardModule,
    DsaIconModule,
    DsaInfoCardModule,
    DsaTooltipModule,
    StoreModule.forFeature(
      fromNotifications.featureKey,
      fromNotifications.reducers
    ),
    TimeAgoPipe,
    JobNotificationsDisplayComponent
  ],
  exports: [JobNotificationsDisplayComponent]
})
export class JobNotificationsDisplayModule {}
