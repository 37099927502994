<h2 style="margin-top: 0">
  @if ((authState$ | async)?.loggedIn) {
    <span>
      {{ salutation }}, {{ givenname$ | async }}!
    </span>
  } @else {
    {{ salutation }}!
  }
</h2>
<p>{{ text }}</p>
