import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { NavigationActions } from '../actions/navigation.actions';

@Injectable()
export class NavigationEffects {
  private readonly actions$ = inject(Actions);
  private readonly location = inject(Location);

  onNavigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.navigateBack),
        tap(_ => this.location.back())
      ),
    { dispatch: false }
  );
}
