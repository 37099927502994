import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DsaAppShellComponent,
  DsaAppShellModule
} from '@dsa/design-system-angular';
import { DsaCardModule } from '@dsa/design-system-angular/card';
import { DsaIconModule } from '@dsa/design-system-angular/icon';
import { FlexLayoutModule } from 'ng-flex-layout';
import { JobNotificationsDisplayModule } from '../dynamic-connect/job-notifications-display/job-notifications-display.module';
import { windowProvider, WindowToken } from '../shared/providers/window';
import { GreetingComponent } from './components/greeting/greeting.component';
import { InstanaComponent } from './components/instana/instana.component';
import { HasRolePipe } from './pipes/has-role.pipe';

@NgModule({
  imports: [
    CommonModule,
    DsaAppShellModule,
    FlexLayoutModule,
    DsaIconModule,
    DsaCardModule,
    JobNotificationsDisplayModule,
    HasRolePipe,
    InstanaComponent,
    GreetingComponent
  ],
  exports: [
    DsaCardModule,
    InstanaComponent,
    HasRolePipe,
    GreetingComponent,
    DsaAppShellComponent
  ],
  providers: [{ provide: WindowToken, useFactory: windowProvider }]
})
export class CoreModule {}
