import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedPayload } from '@ngrx/router-store';
import { map, tap } from 'rxjs/operators';
import { InstanaService } from '../services/instana.service';

@Injectable()
export class InstanaEffects {
  private readonly actions$ = inject(Actions);
  private readonly instanaService = inject(InstanaService);

  notifyPageChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        map((action: any) => {
          return action.payload;
        }),
        tap((p: RouterNavigatedPayload<any>) => {
          this.instanaService.setPage(p.event.url);
        })
      ),
    {
      dispatch: false
    }
  );
}
