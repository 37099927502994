import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { SuggesterActions } from '../actions/dcco-exceptionsuggester.actions';
import { DynamicConnectExceptionSuggesterService } from '../services/dcco-exception-suggester.service';

@Injectable()
export class SuggestEffects {
  private readonly actions$ = inject(Actions);
  private readonly suggester = inject(DynamicConnectExceptionSuggesterService);

  onSuggest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuggesterActions.suggestRequest),
      switchMap(req =>
        this.suggester
          .searchSuggestions(req.searchString, req.searchField)
          .pipe(
            map(suggestions =>
              SuggesterActions.suggestSuccess({
                searchField: req.searchField,
                suggestions: suggestions
              })
            )
          )
      )
    )
  );
}
