import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiEndpointsService } from '@app/modules/core/services/api-endpoints.service';
import { QueryParams } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { HotelChainDropdown } from '../../shared/data/hotel-chain-dropdown';
import { HotelChain } from '../data/hotel-chain';
import { HotelChainIndex } from '../data/hotel-chain-index';
import { SearchOutput } from '../data/searchOutput';
import { TemplateType } from '../data/templateType';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class HotelChainService implements DataService<HotelChain> {
  private readonly http = inject(HttpClient);
  private readonly api = inject(ApiEndpointsService);

  constructor() {
    this._lastParams = {};
  }
  private _lastParams: QueryParams;

  search(
    force: boolean,
    queryParams?: QueryParams
  ): Observable<SearchOutput<HotelChainIndex>> {
    const params: QueryParams = {
      limit: '15',
      offset: '0',
      orderBy: '',
      ...(queryParams ?? this._lastParams)
    };

    if (!this.paramsDidChange(params) && !force) {
      return of(null);
    }

    this._lastParams = params;

    return this.http
      .get<SearchOutput<HotelChainIndex>>(
        this.api.endpoints.dynamicConnect + '/HotelChain/Search',
        {
          observe: 'response',
          responseType: 'json',
          params
        }
      )
      .pipe(
        map(resp => resp.body),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  get(hotelChainCode: string, supplier: string): Observable<HotelChain> {
    return this.http.get<HotelChain>(
      this.api.endpoints.dynamicConnect + '/HotelChain',
      { params: { hotelChainCode, supplier } }
    );
  }

  getDropdown(): Observable<HotelChainDropdown[]> {
    return this.http.get<HotelChainDropdown[]>(
      this.api.endpoints.dynamicConnect + '/HotelChain/Dropdown'
    );
  }

  getTemplate(templateType: TemplateType): Observable<HttpResponse<Blob>> {
    return this.http.get(this.api.endpoints.dynamicConnect + '/File/template', {
      params: {
        templateType
      },
      responseType: 'blob',
      observe: 'response'
    });
  }

  upsert(hotelChain: HotelChain): Observable<string> {
    return this.http.post<string>(
      this.api.endpoints.dynamicConnect + '/HotelChain',
      hotelChain
    );
  }

  delete(hotelChainIndex: HotelChainIndex): Observable<string> {
    return this.http.delete<string>(
      this.api.endpoints.dynamicConnect + '/HotelChain',
      { body: hotelChainIndex }
    );
  }

  excelImport(
    excelFile: File,
    hotelChainCode: string,
    supplier: string,
    overrideHotels: boolean,
    templateType: TemplateType
  ): Observable<HotelChain> {
    const formData = new FormData();
    formData.append('excelFile', excelFile);
    formData.append('hotelChainCode', hotelChainCode);
    formData.append('supplier', supplier);
    formData.append('overrideHotels', `${overrideHotels}`);
    formData.append('templateType', `${templateType}`);

    const options = {
      reportProgress: true
    };

    return this.http.post<HotelChain>(
      this.api.endpoints.dynamicConnect + '/HotelChain/ExcelImport',
      formData,
      options
    );
  }

  exists(hotelChainCode: string, supplier: string): Observable<boolean> {
    return this.http.get<boolean>(
      this.api.endpoints.dynamicConnect + '/HotelChain/Exists',
      { params: { hotelChainCode, supplier } }
    );
  }

  private paramsDidChange(params: QueryParams): boolean {
    return JSON.stringify(this._lastParams) !== JSON.stringify(params);
  }
}

export class HotelChainServiceStub {
  search(
    _force: boolean,
    _queryParams?: QueryParams
  ): Observable<SearchOutput<HotelChainIndex>> {
    return of();
  }
  get(_hotelChainCode: string, _supplier: string): Observable<HotelChain> {
    return of({} as HotelChain);
  }
  getDropdown(): Observable<HotelChainDropdown[]> {
    return of([]);
  }
  getTemplate(_templateType: TemplateType): Observable<HttpResponse<Blob>> {
    return of(new HttpResponse<Blob>());
  }
  /* istanbul ignore next */
  upsert(_hotelChain: HotelChain): Observable<string> {
    return of('');
  }
  /* istanbul ignore next */
  delete(_hotelChain: HotelChainIndex): Observable<string> {
    return of('');
  }
  excelImport(
    _excelFile: File,
    _hotelChainCode: string,
    _supplier: string,
    _overrideHotels: boolean
  ): Observable<string> {
    return of('');
  }
  /* istanbul ignore next */
  exists(_hotelChainCode: string, _supplier: string): Observable<boolean> {
    return of({} as boolean);
  }
}
