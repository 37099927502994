import { Injectable } from '@angular/core';
import {
  EntityCollectionReducerMethodMap,
  EntityDefinitionService
} from '@ngrx/data';
import { AdditionalEntityCollectionReducerMethods } from './additional-entity-collection-reducer-methods';

@Injectable()
export class AdditionalEntityCollectionReducerMethodsFactory {
  // Using old injection for compatibility with old Code
  // Code is needed for depricated ngrx data and should be removed with it
  constructor(
    private readonly entityDefinitionService: EntityDefinitionService
  ) {}

  create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
    const definition =
      this.entityDefinitionService.getDefinition<T>(entityName);
    const methodsClass = new AdditionalEntityCollectionReducerMethods(
      entityName,
      definition
    );
    return methodsClass.methods;
  }
}
