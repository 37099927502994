import { Injectable, inject } from '@angular/core';
import { ToastActions } from '@app/modules/core/actions/toast.action';
import { DsaToastService } from '@dsa/design-system-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable()
export class ToastEffects {
  private readonly actions$ = inject(Actions);
  private readonly toastService = inject(DsaToastService);

  showInfoToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToastActions.infoToast),
        tap(_ => this.toastService.info(_))
      ),
    { dispatch: false }
  );

  showSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToastActions.successToast),
        tap(_ => this.toastService.success(_))
      ),
    { dispatch: false }
  );

  showWarningToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToastActions.warningToast),
        tap(_ => this.toastService.warning(_))
      ),
    { dispatch: false }
  );

  showErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToastActions.errorToast),
        tap(_ => this.toastService.danger(_))
      ),
    { dispatch: false }
  );
}
