import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { ToastActions } from '../../core/actions/toast.action';
import { FileImportActions } from '../actions/file-import.actions';

@Injectable()
export class FileImportEffects {
  private readonly actions$ = inject(Actions);

  onCloseUploadFileDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileImportActions.closeUploadFileDialog),
      map(_ => ToastActions.successToast({ title: 'Upload successful' }))
    )
  );
}
