import { Injectable, inject } from '@angular/core';
import { ToastActions } from '@app/modules/core/actions/toast.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MasterDataActions } from '../actions/masterdata.actions';
import { MasterdataService as MasterDataService } from '../services/masterdata.service';

@Injectable()
export class MasterDataEffects {
  private readonly actions$ = inject(Actions);
  private readonly masterDataService = inject(MasterDataService);

  onRequestRoomTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterDataActions.getRoomTypes),
      switchMap(req =>
        this.masterDataService.getRoomTypes(req.tenant, req.roomInput).pipe(
          map(returnedRooms =>
            MasterDataActions.getRoomTypesSuccess({
              roomTypes: returnedRooms
            })
          ),
          catchError(error => {
            return of(
              ToastActions.errorToast({
                title: 'An Error Occurred',
                description: error.message
              })
            );
          })
        )
      )
    )
  );
}
