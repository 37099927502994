import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';
import { RequestMemos } from '../data/request-memos';

@Injectable({
  providedIn: 'root'
})
export class MemoService {
  private readonly http = inject(HttpClient);
  private readonly api = inject(ApiEndpointsService);

  getMemos(
    tourOperator: string,
    reservationNumber: string,
    version: string
  ): Observable<RequestMemos> {
    const url = new URL(
      `/api/requests/${tourOperator}/${reservationNumber}/${version}/memos`,
      this.api.endpoints.requests
    ).toString();

    return this.http
      .get<RequestMemos>(url, {
        observe: 'response',
        responseType: 'json'
      })
      .pipe(map(resp => resp.body));
  }
}
