import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  DefaultDataServiceConfig,
  EntityCollectionDataService,
  HttpUrlGenerator
} from '@ngrx/data';
import { ApiEndpointsService } from '../services/api-endpoints.service';
import { CoreDataService } from './core-data-service';

@Injectable()
export class CoreDataServiceFactory {
  private readonly http = inject(HttpClient);
  private readonly urlGenerator = inject(HttpUrlGenerator);
  private readonly apiEndpointsService = inject(ApiEndpointsService);
  private readonly config = inject(DefaultDataServiceConfig);

  create<T>(entityName: string): EntityCollectionDataService<T> {
    return new CoreDataService<T>(
      entityName,
      this.http,
      this.urlGenerator,
      this.apiEndpointsService,
      this.config
    );
  }
}
