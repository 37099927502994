<div class="button">
  <dsa-icon class="icon" (click)="toggle()">notifications</dsa-icon>

  <dsa-badge
    *ngIf="newCount$ | async"
    role="danger"
    text="{{ newCount$ | async }}"
  ></dsa-badge>
</div>

<div *ngIf="isOpen" class="box mat-elevation-z10">
  <div class="header">
    <div>Notifications</div>
    <dsa-icon class="close-icon" (click)="toggle()">clear</dsa-icon>
  </div>

  <div class="list">
    <dsa-info-card
      *ngFor="let n of notifications$ | async; trackBy: getId"
      infoType="{{ getType(n.jobStatus) }}"
      (close)="remove(n)"
      [ngSwitch]="n.jobStatus"
      [ngClass]="{ 'notif-seen': !n.isNew }"
    >
      <dsa-info-card-title class="{{ getType(n.jobStatus) }}">
        <span>Job {{ n.jobStatus }}</span>
        <span
          class="color-grey-07 font-regular"
          dsaTooltip="{{ n.time.toLocaleString() }}"
          dsaTooltipShowDelay="500"
          >&nbsp;{{ n.time | timeAgo }}
        </span>
      </dsa-info-card-title>

      <div *ngSwitchCase="'Scheduled'">
        <b>{{ n.jobName }}</b> is waiting for its time to run.
      </div>
      <div *ngSwitchCase="'Enqueued'">
        <b>{{ n.jobName }}</b> is waiting for an available job worker.
      </div>
      <div *ngSwitchCase="'Started'">
        <b>{{ n.jobName }}</b> is currently executing.
      </div>
      <div *ngSwitchCase="'Finished'">
        <b>{{ n.jobName }}</b> completed successfully.
      </div>
      <div *ngSwitchCase="'Failed'">
        <b>{{ n.jobName }}</b> failed: {{ n.error }}
      </div>
      <div *ngSwitchCase="'Deleted'">
        <b>{{ n.jobName }}</b> has been deleted.
      </div>
    </dsa-info-card>

    <div *ngIf="(notifications$ | async).length == 0" class="empty-msg">
      <dsa-icon style="font-size: 96px">notifications_off</dsa-icon>
      <div>No notifications to show</div>
    </div>
  </div>
</div>
