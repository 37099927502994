import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import * as fromRoot from '@app/reducers';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { catchError, Observable, throwError } from 'rxjs';
import { CoreActions } from '../actions/core.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly store = inject<Store<fromRoot.State>>(Store);
  private readonly oauthService = inject(OAuthService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.oauthService.hasValidAccessToken()) {
      request = request.clone({
        setHeaders: {
          Authorization: `bearer ${this.oauthService.getAccessToken()}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          this.store.dispatch(CoreActions.userLogin());
        }
        return throwError(() => err);
      })
    );
  }
}
