import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastActions } from '../../core/actions/toast.action';
import { HotelChainDropdownActions } from '../actions/hotelchain-dropdown.actions';
import { HotelChainDropdownService } from '../services/hotelchain-dropdown.service';

@Injectable()
export class HotelChainDropdownEffects {
  private readonly actions$ = inject(Actions);
  private readonly hotelChainDropdownService = inject(
    HotelChainDropdownService
  );

  onGetHotelChainDropdown$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HotelChainDropdownActions.get),
      switchMap(_ =>
        this.hotelChainDropdownService.get().pipe(
          map(hotelChainDropdown =>
            HotelChainDropdownActions.getSuccess({
              hotelChainDropdown
            })
          ),
          catchError(error =>
            of(
              ToastActions.errorToast({
                title: 'An Error Occurred',
                description: error.message
              })
            )
          )
        )
      )
    )
  );
}
