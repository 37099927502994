import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastActions } from '../../core/actions/toast.action';
import { FlightInfoActions } from '../actions/flightinfo.actions';
import { FlightInfoService } from '../services/flightinfo.service';

@Injectable({
  providedIn: 'root'
})
export class FlightInfoEffects {
  private readonly actions$ = inject(Actions);
  private readonly flightInfoService = inject(FlightInfoService);

  onRequestFlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlightInfoActions.getFlightInfos),
      switchMap(req =>
        this.flightInfoService
          .get(req.tourOperator, req.externalReservationNumber, req.version)
          .pipe(
            map(returnedFlights =>
              FlightInfoActions.getFlightsSuccess({
                flightInfos: returnedFlights
              })
            ),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 404) {
                return of(
                  FlightInfoActions.getFlightsSuccess({
                    flightInfos: []
                  })
                );
              }

              return of(
                FlightInfoActions.clearFlightInfosStore(),
                ToastActions.errorToast({
                  title: 'An Error Occurred',
                  description:
                    typeof error.error === 'string'
                      ? error.error
                      : error.statusText
                })
              );
            })
          )
      )
    )
  );
}
